.News {
  padding: 100px 0px;
}
.News .news-card-2 {
  margin-top: 27px;
}
.News .news-card-4 {
  margin-top: 27px;
}
.News .news-card-6 {
  margin-top: 27px;
}
